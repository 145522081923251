import React, { useState } from 'react'
import { Link } from 'gatsby'
export default function NavlinkBlock(props) {
    const [active, setActive] = useState(false)
    return (
        <Link to={props.to} activeClassName={"activeNavLink"} className="lg:mr-6 lg:ml-6 lg:my-0 my-10 pd-12 lg:h-full h-20 flex flex-col justify-center items-center group relative">
            <div className="w-0 group-hover:w-full transition-all ease-in-out duration-300 bg-blue h-2px absolute top-0"></div>
            <h4 className="text-black font-normal lg:text-base text-xl group-hover:text-blue px-2">{props.children}</h4>
        </Link>
    )
}
