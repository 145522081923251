import React from 'react'
import { Link } from "gatsby"
export default function Logo() {
    return (
        <Link to="/" className="py-2">
            <img src={"/images/flying-point-logo.png"} alt="Flying Point Industries" className="w-32" />
        </Link>

    )
}
