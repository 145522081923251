import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../utils/Logo"
import NavlinkBlock from "./NavlinkBlock"
const genericHamburgerLine = `h-1 w-4 my-0.5 rounded-full bg-white transition ease transform duration-300`;


export default function Header(props) {
  const genericHamburgerLine = `h-1 w-4 my-0.5 rounded-full bg-blue transition ease transform duration-300`;
  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => {
    setIsOpen(!isOpen)
  };
  return (
    <header className="flex w-full lg:px-12 px-6 bg-white sticky top-0 z-50 items-center h-20">
      <Logo></Logo>
      <div className="lg:flex h-full hidden ml-auto justify-between font-tw items-center">
        <NavlinkBlock to="/">Home</NavlinkBlock>
        <NavlinkBlock to="/our-story">Our Story</NavlinkBlock>
        <NavlinkBlock to="/portfolio">Portfolio</NavlinkBlock>
        <NavlinkBlock to="/news">News</NavlinkBlock>
        <NavlinkBlock to="/contact-us">Contact Us</NavlinkBlock>
        <div className="nav-divider"></div>
        <NavlinkBlock to="/login">Login</NavlinkBlock>
      </div>
      <button
        className="ml-auto lg:hidden flex flex-col h-12 w-8 rounded justify-center items-center group outline-none overflow-hidden focus:outline-none z-20"
        onClick={toggleNav}
      >
        <div
          className={`${genericHamburgerLine} mr-auto ${isOpen
            ? "rotate-45 translate-y-2 translate-x-2 opacity-100 group-hover:opacity-100"
            : "opacity-100 group-hover:opacity-100"
            }`}
        />
        <div
          className={`${genericHamburgerLine} w-full ${isOpen ? "opacity-0 -translate-x-8" : "opacity-100 group-hover:opacity-100"
            } `}
        />
        <div
          className={`${genericHamburgerLine} ml-auto ${isOpen
            ? "-rotate-45 -translate-y-2 -translate-x-2 opacity-100 group-hover:opacity-100"
            : "opacity-100 group-hover:opacity-100 "
            } `}
        />
      </button>
      <div className={`${isOpen ? 'w-full opacity-100' : 'w-0 opacity-0'} h-screen transition-all duration-500 ease-in-out bg-lightblue lg:hidden flex flex-col justify-center items-center absolute top-0 right-0 overflow-hidden py-20`}>
        <NavlinkBlock to="/">Home</NavlinkBlock>
        <NavlinkBlock to="/our-story">Our Story</NavlinkBlock>
        <NavlinkBlock to="/portfolio">Portfolio</NavlinkBlock>
        <NavlinkBlock to="/news">News</NavlinkBlock>
        <NavlinkBlock to="/contact-us">Contact Us</NavlinkBlock>
        <div className="nav-divider lg:block hidden"></div>
        <NavlinkBlock to="/login">Login</NavlinkBlock>
      </div>
    </header>
  )
}
