import React from 'react'
import FooterHeading from './common/FooterHeading';
import FooterText from './common/FooterText';

export default function Footer() {
    return (
        <div className="bg-footer w-full flex flex-col pt-8 md:pb-0 pb-4 md:justify-center md:items-center md:px-6 px-6">
            <div className="md:grid lg:w-10/12 md:w-full md:grid-cols-footer md:grid-rows-1 grid-rows-3 grid-cols-1">
                <div className="flex flex-col w-full md:mb-0 mb-8">
                    <FooterHeading>Let us guide the way.</FooterHeading>
                    <FooterText>FlyingPoint Industries</FooterText>
                    <FooterText>1325 Avenue of the Americas</FooterText>
                    <FooterText>28th Floor</FooterText>
                    <FooterText>New York, NY, 10019</FooterText>
                </div>
                <div className="flex flex-col w-full md:mb-0 mb-8">
                    <FooterHeading>Contact Us</FooterHeading>
                    <FooterText>T: (212) 763-8430</FooterText>
                </div>
                <div className="flex flex-col md:justify-start md:items-center w-full md:mb-0 mb-8">
                    <FooterHeading>Get In Touch</FooterHeading>
                    <div className="flex flex-row">
                        <a href="#" target="_blank" className="mr-8">
                            <img src="/images/instagram.png" alt="" className="w-6 transition-all duration-300 transform hover:scale-110" />
                        </a>
                        <a href="#" target="_blank" >
                            <img src="/images/facebook.png" alt="" className="w-3 transition-all duration-300 transform hover:scale-110" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex md:justify-center lg:items-center lg:p-2 lg:mt-2 mt-4 mb-2">
                <FooterText>© 2019 flyingpointindustries.com. All rights reserved. Privacy Policy</FooterText>
            </div>
        </div>
    )
}
